
.main-NavBar{
    padding: 10px 15px 0 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    
}
.nav-contactinfo-section{
    width: 90vw;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 30px;
    padding-top: 1%;
}
.nav-contactinfo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.nav-contactinfo-text{
    font-size: 1rem;
    text-decoration: none;
    transition: ease;
    text-underline-offset: 10px;
    color: #000000;
    
}
.nav-contactinfo-text:hover{
    cursor: pointer;
    color: #257432;
    text-decoration: underline;
}
.nav-second-sec{
    width: 70vw;
    display: flex;
    padding-bottom: 15px;
}
.jawa-logo{
    display: flex;
    justify-content: center;
    width: 30%;
    height: 13vh;
}
.jawa-img{
    height: 100%;
}
.nav-bar{
    width: 100%;
    font-size:1.3rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 3%;
}

.nav-links {
    position: relative; 
    color: #257432;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    
}

.nav-links::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: #257432;
    z-index: -1;
    transition: height 0.3s ease;
}

.nav-links:hover::before {
    height: 80px; 
}

.nav-links:hover {
    color: #ffffff; 
}

.responsive-nav-bar{
    display: none;
}
.nav-toggle, .nav-close {
    display: none;
  }
  
  .nav-toggle {
    padding-right: 10px;
    cursor: pointer;
    z-index: 600;
  }
  @media only screen and (max-width:1024px){
    .nav-bar{
        font-size: 1.1rem;
    }

  }
  @media only screen and (max-width:1010px){
    .nav-contactinfo{
        width: 100%;
    }
    .nav-second-sec{
        width: 100%;
    }
  }
@media only screen and (max-width:770px){
    .main{
        padding: 0;
    }
    .nav-contactinfo-section{
        display: none;
    }
    .nav-bar{
        display: none;
    }
    .responsive-nav-bar{
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        height: 100%;
        gap: 50px;
        font-size: 20px;
        position: fixed;
        top: 0;
        right: -100%;
        overflow-x: hidden;
        transition: right 0.5s;
        background-color: rgb(255, 255, 255);
        z-index: 500;
    }
    .responsive-nav-links{
        text-decoration: none;
        color: rgb(0, 0, 0);
    }
    .nav-toggle, .nav-close {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        position: relative;
    }
    .responsive-nav-bar.open {
        right: 0;
    }
    .responsive-social-sec-links{
        text-decoration: none;
        color: rgb(0, 0, 0);
    }
    .responsive-social{
        display:flex;
        flex-direction: column;
        justify-content: center;
gap:15px;
    }
    .responsive-jawa-img{
        height: 15vh;

    }
    .jawa-logo{
        width: auto;
        height: 10vh;
    }
}