
.main-overview{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px 0;
}
.main-overview-content{
    height: 100%;
    width: 66vw;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.overview-image1-container{
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overview-image1{
    border-radius: 2vw 0 0 0;
    height: 100%;
    width: 100%;
}
.overview-text{
    width: 85%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.overview-eng-main{    
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.overview-arabic-text-main{
    display: flex;
    flex-direction: column;
    direction: rtl;
    gap: 15px;
}
.overview-image2-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overview-image2{
    border-radius: 0 0 4vw 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}






@media only screen and (max-width:770px){
    .main-overview{
        padding:20px 0px;
        height: 100%;
    }
    .main-overview-content{
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;  
    }
    .overview-image1-container{
        display: none;
    }
    
    .overview-text{
        gap: 25px;
        width:100%;
    
    }   
}