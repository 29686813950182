#ActivitiesText{
    width: 82%;
    }
.main-activities{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px 0;
}
.main-activities-content{
    height: 100%;
    width: 66vw;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.activities-image1-container{
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activities-image1{
    border-radius: 2vw 0 0 0;
    height: 100%;
    width: 100%;
}
.activities-text{
    width: 87%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.activities-eng-main{    
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.activities-arabic-text-main{
    display: flex;
    flex-direction: column;
    direction: rtl;
    gap: 15px;
}
.activities-image2-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.activities-image2{
    border-radius: 0 0 4vw 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#moreActivities{
    background-Color: #ffffff;
    color: rgba(19,127,184);
    border: 1px solid rgba(19,127,184);
}
#moreActivities:hover{
    color: #ffffff;
    background-Color:rgba(19,127,184);
}
@media only screen and (max-width:770px){
    #moreActivities{
        color: #ffffff;
        background-Color:rgba(19,127,184);
    }
    .main-activities{
        padding:20px 0px;
        height: 100%;
    }
    .main-activities-content{
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;  
    }
    .activities-image1-container{
        display: none;
    }
    
    .activities-text{
        width: 100%;
        gap: 25px;
    }   
    #ActivitiesText{
        width: 100%;
        }
}

