.Goals{
    min-height: 45.2vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    padding: 2% 0;
}
.goals-section1{
    gap: 20px;
    min-height: 40vh;
    width: 61vw;
    display: flex;
    align-items: flex-start;
}
.goals-image1-container{
    width: 100px;
}
.goals-image1{
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.goals-eng-main{    
    width: 79%;
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.goals-section2{
    min-height: 30vh;
    width: 61vw;
    display: flex;
    gap: 20px;
}

.goals-arabic-text-main{
    width: 90%;
    display: flex;
    flex-direction: column;
    direction: rtl;
    gap: 15px;
}

.goals-image2-container{
    width: 70%;
    height: 100%;
}

.goals-image2{
    width: 75%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width:1024px){
    .goals-eng-main{ 
        width: 100%;
    }
    .goals-image1-container{
        width: 65px;
    }
    .goals-image2{
        width: 100%;
    }
}

@media only screen and (max-width:770px){
    .Goals{
        padding:6% 3%;
    }
    .goals-eng-main{
        width: 100%;
    }
    .goals-image1-container{
        display: none;
    }
    .goals-section1{
        width: 100%;
    }
    .goals-section2{
        width: 100%;
        flex-direction: column-reverse;
    }
    .goals-arabic-text-main{
        width: 100%;
    }
    .arabic-desc{
        width:100%;
    }
    .goals-image2-container{
        width: 100%;
        padding: 0;
    }
}