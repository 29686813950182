.VST{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 0;
}
.main-VST{
    display: flex;
    background-color: rgba(239, 239, 239, 239);
    border-radius: 0 4vw 4vw 4vw;
    width: 66vw;
    justify-content: flex-end;
    gap: 10px;
}
.texts-VST-main{
    padding: 2% 0.5% 4% 0%;
    width: 41%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.eng-VST-main{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.arabic-VST-main{
    padding: 0 10px;
    direction: rtl;
    display: flex;
    gap: 15px;
    height: 48%;
    flex-direction: column;
}
.imagesSection{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
}
.VST-image1-container{
    height: 100%;
    width: 100%;
    padding-top: 5%;
}
.VST-image1{
    height: 80%;
    object-fit: cover;
    
}
.VST-image2-container{
   height: 50%;
   border-radius: 0  0 4vw 0 ;
}
.VST-image2{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 4vw 0 ;
}
#moreVST{
    border:1px solid #22652d;
    background-Color: rgba(239, 239, 239, 239);
    color: #22652d;
}
#moreVST:hover{
    color: #ffffff;
    background-Color:#22652d;
}
@media only screen and (max-width:1100px){
    .imagesSection{
        width: 45%;
    }
}
@media only screen and (max-width:770px){
    #moreVST{
        color: #ffffff;
        background-Color:#22652d;
    }
    .main-VST{
        flex-direction: column-reverse;
        width: 100%;
    }
    .VST-image1-container{
        display: none;
    }
    .imagesSection{
        width: 100%;
    }
    .texts-VST-main{
        width: 100%;
        
    }
    .VST-image2-container, .imagesSection, .VST-image2{
        margin: 0;
    }
}