.Donations{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}
.main-donations{
    display: flex;
    flex-direction: column;
    width: 66vw;
}
.donations-container{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.donations-image-container{
    display: flex;
    justify-content: flex-start;
    height: 100%;
    width: 200px;
}
.donations-image{
    object-fit: cover;
    height: 100%;
}
.donations-inputs{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 50%;

}
.contact-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    padding: 0% 2% 0% 0%;
}

.contacts-jawa{
    display: flex;
    flex-direction: column;
}
.jawa-links{
    text-decoration: none;
    color: #000000;
}
.jawa-links:hover{
    color: #30793d;
    text-decoration: underline;
    text-underline-offset:5px;

}
.jawa-email{
    display: flex;
}
.donations-text{
    letter-spacing: 1px;
}
.contacts-jawa{
    display: flex;
}
.submit-btn{
    padding:1% 3% 1% 3%;
    border-radius: 25px;
    font-size: 19px;
    width: 110px;
    align-self: flex-start;
    color: rgb(43, 141, 21);
    background-color: #ffffff;
    border: 1px solid rgb(43, 141, 21);
}
.submit-btn:hover{
    cursor: pointer;
    background-color:rgb(43, 141, 21);
    color:#ffffff ;
}
.donations-title-main{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
}
.donations-titles{
    display: flex;
    height: 20vh;
    background-image: url("./../../../../assets/Donations-4.png");
    background-repeat: no-repeat;
    background-size: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 0 21%; 
    gap: 15px;
}
.donations-hr1{
    border: thin solid rgb(244, 182, 182);
    width: 60px;
    margin: 0; 
}

.donations-small-title{
    font-size: clamp(15px, 0.8vw, 40px);
    color: #22652d;
}
.contact-us-eng-title{
    letter-spacing: 1px;
    font-family: "Balthazar";
    color: #22652d;
}
@media only screen and (max-width:1400px){
    .donations-titles{
        height: 15vh;
        padding: 0;
    }
}
@media only screen and (max-width:1024px){
    .donations-titles{
        background-image: none;
    }
}
@media only screen and (max-width:770px){
    .main-donations{
        background-image: none;
    }
    .submit-btn{
        background-color: rgb(43, 141, 21);
        color: #ffffff;
    }
    .main-donations{
        width: 100%;
    }
    .donations-image-container{
        display: none;
    }
    .jawa-location-container{
        width: auto;
    }
    .donations-title-main{
        width: 100%;
        justify-content: unset;
    }
    .donations-container{
        background-size: 200vw;
    }
}
@media only screen and (max-width:480px){
    
    .donations-container{
        flex-direction: column;
    }
    .donations-inputs{
        width: 100%;
    }
    .contact-info{
        gap: 10px;
    }
}