@import url('https://fonts.googleapis.com/css2?family=Balthazar&display=swap');

.main-footer{
    background-image: url("../../assets/Footer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    

}
.footer-sec{
    align-items: flex-start;
    display: flex;
    justify-content: center;
    gap:70px;
}
.footer-menu{
    padding:2%;
}
.media-main{
    padding: 2%;
}
.footer-title{
    font-size: 1rem;
    font-weight: unset;
    font-family: "Balthazar";
    color: #ffffff;
}
.footer-logo-a{
    padding-top: 1.5%;
    width: 140px;
}
.footer-jawa-img{
    width: 100%;

}
.footer-menu{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
}
.footer-links{
    text-decoration: none;
    font-size: 0.9rem;
    color: #ffffff;
}

.rights{
    font-family:Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    background-color: #ffffff82;
    height: 40px;
    
}
.media-main{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.media{
    display: flex;
    flex-direction: column;
}
.media-links{
    font-size: 0.9rem;
    gap: 4px;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.in2info{
    height: 12px;
}
.in2infoLink{
    text-decoration: none;
}
.media{
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width:770px) {
    .footer-sec{
        padding-top: 5%;
        gap: 60px;
    }
}
@media only screen and (max-width:500px) {
    .footer-logo-a{
        width: 120px;
    }
    .footer-sec{
        flex-direction: column;
        gap: 20px;
        padding-top: 8%;
    }
    .footer-links{
        width: auto;
        
    }
    .footer-menu{
        align-items: flex-start;
    
    }
    .rights{
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        gap: 0px;
    }


    
}