@import url('https://fonts.googleapis.com/css2?family=Balthazar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Farah&display=swap');

*{
    margin: 0;

}
.more-link{
    font-family:Arial, Helvetica, sans-serif;
    padding:1% 3% 1% 3%;
    border-radius: 25px;
    border: 1px solid #d95345;
    font-size: 19px;
    text-decoration: none;
    align-self: flex-start;
    background-color: #fff;
    color: #d95345;
    
}

.more-link:hover{
    cursor: pointer;
    background-color:#d95345;
    color: #fff;
}
.title-main{
    
    display: flex;
    align-items: center;
    gap: 8px;
}
.hr1{
    border: thin solid rgb(244, 182, 182);
    width: 60px;
    margin: 0; 
}

.small-title{
    font-size: clamp(15px, 0.8vw, 40px);
    color: #22652d;
}
.arabic-title-main{
    display: flex;
    align-items: center;
    gap: 8px;
}
.hr2{
    border: thin solid rgb(244, 182, 182);
    width: 60px;
    margin: 0; 
}
.arabic-small-title{
    font-family: 'Farah';
    font-weight: unset;
    font-size: clamp(20px, 1.1vw, 50px);
    color: #22652d;
}
.eng-title{ 
    letter-spacing: 1px;
    font-family: "Balthazar";
    color: #22652d;
    font-size: clamp(24px, 1.6vw, 50px);
}
.second-title{
    font-size: clamp(20px, 1.3vw, 50px);
    font-family: "Balthazar";
    color: #22652d;
}
.eng-sub-titles,.arabic-sub-title{
    font-size: clamp(18px, 1.1vw, 50px);
    font-family: "Balthazar";
    color: #22652d;
}
.eng-title-main{ 
    font-family: "Balthazar";
    color: #22652d;
    font-size: clamp(18px, 2vw, 60px);
}
.arabic-title-main{
    direction: rtl;
}
.arabic-title{
    font-size: clamp(24px, 1.4vw, 50px);
    color: #22652d;
}

.arabic-desc{
    font-weight:thin;
    text-align: justify;
    font-size: clamp(15px, 1vw, 50px);
    
}
.eng-desc{
    line-height: 20px;
    text-align: justify;
}
@media only screen and (max-width:770px){
    .more-link{
        background-color:#d95345;
        color: #fff;
    }
    .home{
        padding:0px 10px;
    }

    .title-main{
        width: 100%;
     }
     .small-title{
        font-size: 0.8rem;
     }
    .eng-title,.arabic-title{
        font-size: 1.3rem;
    }
    .arabic-desc{
        font-size: 1.1rem;
    }
}