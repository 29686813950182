.Activities{
    min-height: 45.2vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    padding: 2% 0;
    }
    
    .actvities-section1{
        gap: 20px;
        min-height: 40vh;
        width: 61vw;
        display: flex;
        align-items: flex-start;
    }
    .actvities-image1-container{
      width: 100px;
    }
    .actvities-image1{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .actvities-eng-main{    
        width: 79%;
        display: flex;
        flex-direction: column;
        gap: 17px;
    }
    
    .actvities-section2{
        min-height: 40vh;
        width: 61vw;
        display: flex;
        gap: 20px;
    }
    
    .actvities-arabic-text-main{
        width: 50%;
        display: flex;
        flex-direction: column;
        direction: rtl;
        gap: 15px;
    }

    .actvities-image2-container{
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 38%;
        height: 100%;
    }
    .actvities-image2-a{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .actvities-image2-b{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .actvities-image2-c{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .actvities-image2-d{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
        @media only screen and (max-width:1024px){
        .actvities-eng-main{
            width: 100%;
        }
        .actvities-image1-container{
            width: 80px;
        }
        .actvities-image2-container{
            width: 50%;
        }
    }
    @media only screen and (max-width:770px){
        .Activities{
            padding:6% 3%;
        }
        .actvities-eng-main{
            width: 100%;
        }
        .actvities-image1-container{
            display: none;
        }
        .actvities-section1{
            width: 100%;
        }
        .actvities-section2{
            width: 100%;
            flex-direction: column-reverse;
        }
        .actvities-arabic-text-main{
            width: 100%;
        }
        .arabic-desc{
            width:100%;
        }
        .actvities-image2-container{
            width: 100%;
            padding: 0;
        }
    }