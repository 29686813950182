
.GOALS{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 40px ;
}
.main-GOALS{
    display: flex;
    background-color: rgba(239, 239, 239, 239);
    border-radius: 0 4vw 4vw 4vw;
    width: 66vw;
    justify-content: flex-end;
    gap: 10px;
}
.texts-GOALS-main{
    padding: 2% 0.5% 4% 0%;
    width: 41%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}
.eng-GOALS-main{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.arabic-GOALS-main{
    padding: 0 10px;
    direction: rtl;
    display: flex;
    gap: 15px;
    height: 48%;
    flex-direction: column;
}
.Goals-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 49%;
}
.Goals-image{
    width: 70%;
}
#moreGoals{
    background-Color: rgba(239, 239, 239, 239);
    color: rgba(249,206,60);
    border: 1px solid rgba(249,206,60);
}
#moreGoals:hover{
    background-Color:rgba(249,206,60);
    color:  rgba(239, 239, 239, 239);
}
@media only screen and (max-width:1100px){
    .Goals-image-container{
        width: 45%;
    }
    .Goals-image{
        width: 90%;
    }
}
@media only screen and (max-width:770px){
    #moreGoals{
    background-Color:rgba(249,206,60);
    color:  rgba(239, 239, 239, 239);
    }
    .main-GOALS{
        flex-direction: column-reverse;
        width: 100%;
    }
    .texts-GOALS-main{
        width: 100%;
        
    }
    .Goals-image-container{
        width: 100%;
        margin: 0;
        
    }
    .Goals-image{
        width:70%;
    }
}
