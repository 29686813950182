.hero-section{
    border-bottom: 1px solid #d95345;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
}
.texts{
 height:30vh;
 width: 70vw;
 display: flex;
 justify-content: space-around;
 align-items: stretch; 
}
.eng-text{
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-direction: column;
    width: 45%;
}
.hero-eng-title{
    letter-spacing: 1px;
    font-family: "Balthazar";
    color: #22652d;
    font-size: clamp(24px, 1.8vw, 50px);
}
.eng-description{
    word-spacing: -3px;
    text-align: justify;
    font-size: clamp(15px, 1vw, 50px);
    font-size: 18px;
  }
.arabic-description{
    background-image: url(../../../../assets/Hero_Background1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
    text-align: justify;
    font-size: clamp(15px, 1vw, 50px);
    font-weight: bold;
    direction: rtl;
    width: 30vw;
    padding-top: 4%;
}
.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.image{
    height: 100%;
    object-fit: cover;
    width: 100%;
}
@media only screen and (max-width:770px){
    .texts{
        width: 100%;
        height: 100%;
        flex-direction: column;  
        gap: 25px;
        background-image:none;
    }
    .eng-text{
        gap: 20px;
        flex-direction: column;  
        width: 100%;
    }
    .eng-title{
        width: 100%;
        font-size: 1.3rem;
    }
    .eng-description{
        word-spacing: 0px;
        width: 100%;
        font-size: 1.1rem;
    }
    .arabic-description{
        background-image: none;
        height: 100%;
        width: 100%;
        font-size: 1.1rem;
    }
    .image-container{
        width: 100%;
    }
}