    .School{
        min-height: 45.2vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 60px;
        padding: 2% 0;
    }
 .school-section1{
        gap: 20px;
        min-height: 40vh;
        width: 61vw;
        display: flex;
        align-items: flex-start;
    }
    .school-image1-container{
      width: 180px;
    }
    .school-image1{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .school-eng-main{    
        width: 71.5%;
        display: flex;
        flex-direction: column;
        gap: 17px;
    }
    
    .school-section2{
        min-height: 40vh;
        width: 61vw;
        display: flex;
        gap: 20px;
    }
    
    .school-arabic-text-main{
        width: 50%;
        display: flex;
        flex-direction: column;
        direction: rtl;
        gap: 15px;
    }

    .school-image2-container{
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 37%;
        height: 100%;
    }
    .school-image2-a{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .school-image2-b{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .list-title{
        letter-spacing: 1px;
        font-family: "Balthazar";
        color: #22652d;
        font-size: clamp(15px, 0.9vw, 45px);
    }

    @media only screen and (max-width:1600px){
        .school-image2-container{
            width: 43%;
        }
    }
    @media only screen and (max-width:1300px){
        .school-image2-container{
            width: 50%;
        }
    }
    @media only screen and (max-width:770px){
        .School{
            padding:6% 3%;
        }
        .school-eng-main{
            width: 100%;
        }
        .school-image1-container{
            display: none;
        }
        .school-section1{
            width: 100%;
        }
        .school-section2{
            width: 100%;
            flex-direction: column-reverse;
        }
        .school-arabic-text-main{
            width: 100%;
        }
        .arabic-desc{
            width:100%;
        }
        .school-image2-container{
            width: 100%;
            padding: 0;
        }
    }