.About-Us{
min-height: 45.2vh;
display: flex;
align-items: center;
flex-direction: column;
gap: 40px;
padding: 2% 0;
}

.aboutus-section1{
    gap: 20px;
    min-height: 40vh;
    width: 61vw;
    display: flex;
    align-items: flex-start;
}
.aboutus-image1-container{
  width: 100px;

}
.aboutus-image1{
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.aboutus-eng-main{    
    width: 79%;
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.aboutus-section2{
    min-height: 40vh;
    width: 61vw;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.aboutus-arabic-text-main{
    width: 60%;
    display: flex;
    flex-direction: column;
    direction: rtl;
    gap: 15px;
}

.aboutus-image2-container{
    width: 40%;
    height: 100%;
}
.aboutus-image2{
    width: 75%;
    height: 100%;
    object-fit: cover;
}
@media only screen and (max-width:1024px){
    .aboutus-eng-main{ 
        width: 100%;
    }
    .aboutus-image1-container{
        width: 65px;
    }
    .aboutus-image2{
        width: 100%;
    }
}
@media only screen and (max-width:770px){
    .About-Us{
        padding:6% 3%;
    }
    .aboutus-eng-main{
        width: 100%;
    }
    .aboutus-image1-container{
        display: none;
    }
    .aboutus-section1{
        width: 100%;
    }
    .aboutus-section2{
        width: 100%;
        flex-direction: column-reverse;
    }
    .aboutus-arabic-text-main{
        width: 100%;
    }
    .arabic-desc{
        width:100%;
    }
    .aboutus-image2-container{
        width: 100%;
        padding: 0;
    }
}